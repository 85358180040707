import * as React from "react"
import Layout from "../components/Layout"
import About from "../images/about-us.png"
import Rocket from "../images/About-Us-Rocket.png"
import System from "../images/About-Us-System.png"
import Book from "../images/About-Us-Book.png"
import "animate.css"

const Aboutus = () => {
  return (
    <div class="">
      <Layout>
        <section class="text-white w-full font-quick body-font ">
          <div class="container px-5 mx-auto">
            <div class="flex flex-col items-center text-center w-full mb-5">
              <h1 class="sm:text-2xl md:text-xl lg:text-4xl border-4 rounded-full px-6 py-2 w-fit font-extrabold title-font mb-4 lg:mb-[2%] animate__animated animate__lightSpeedInLeft">
                Solutions for <span class="text-main">Gamification</span>
              </h1>
              <p class="lg:w-2/3 mx-auto leading-relaxed text-xl lg:text-3xl lg:mb-[1%]">
                Sageflare specializes
                <span class="text-main">
                  Gamification in business and learning process
                </span>
              </p>
              <p class="lg:w-2/3 mx-auto leading-relaxed text-xl lg:text-3xl lg:mb-[1%]">
                our solutions will help audiences to Engage,
              </p>
              <p class="lg:w-2/3 mx-auto leading-relaxed text-xl lg:text-3xl lg:mb-[2%] mb-4">
                Motivate and Achieve.
              </p>

              <h1 class="sm:text-2xl text-xl lg:text-4xl lg:mb-[1%] border-4 border-[#3F0884] bg-[#651FFF] rounded-full px-10 py-2 w-fit font-extrabold title-font relative">
                Reliable IT services for Gamification
              </h1>
            </div>
            <div class="flex flex-wrap -m-2">
              <div class="p-2 lg:w-1/3 md:w-1/2 w-full animate__animated animate__fadeInUp">
                <div class="h-full bg-white p-4 rounded-lg relative">
                  <div class="flex items-start">
                    <img
                      alt="team"
                      class="w-16 h-12 lg:w-[20%] lg:h-[18%] flex-shrink-0 mr-4 absolute left-[8%] top-[14%] animate-rocket"
                      src={Rocket}
                    />
                    <img
                      alt="team"
                      class="w-24 h-24 lg:w-[30%] lg:h-[30%] bg-gray-100 object-cover object-center flex-shrink-0 mr-4"
                      src={About}
                    />
                    <div class="flex-grow">
                      <h2 class="text-gray-900 text-xl lg:text-3xl title-font font-medium">
                        Play to Perform
                      </h2>
                    </div>
                  </div>
                  <p class="text-gray-900 lg:text-2xl">
                    Transform the business as usual workday, which at times, is
                    monotonous, fatiguing or uninspiring, into a day that’s fun,
                    engaging and highly productive.
                  </p>
                </div>
              </div>
              <div class="p-2 lg:w-1/3 md:w-1/2 w-full animate__animated animate__fadeInUp animate__delay-1s">
                <div class="h-full bg-white p-4 rounded-lg relative">
                  <div class="flex items-start">
                    <img
                      alt="team"
                      class="w-16 h-12 lg:w-[20%] lg:h-[18%] flex-shrink-0 mr-4 absolute left-[8%] top-[14%] animate-rocket"
                      src={System}
                    />
                    <img
                      alt="team"
                      class="w-24 h-24 lg:w-[30%] lg:h-[30%] bg-gray-100 object-cover object-center flex-shrink-0 mr-4"
                      src={About}
                    />
                    <div class="flex-grow">
                      <h2 class="text-gray-900 text-xl lg:text-3xl title-font font-medium">
                        Marketing Games
                      </h2>
                    </div>
                  </div>
                  <p class="text-gray-900 lg:text-2xl">
                    Take your marketing to the next level with Custom, branded
                    marketing games. Engage Your audience and attract loads of
                    new Potential customers to your business with this Great
                    interactive experience.
                  </p>
                </div>
              </div>
              <div class="p-2 lg:w-1/3 md:w-1/2 w-full animate__animated animate__fadeInUp animate__delay-2s">
                <div class="h-full bg-white p-4 rounded-lg relative">
                  <div class="flex items-start">
                    <img
                      alt="team"
                      class="w-16 h-12 lg:w-[20%] lg:h-[18%] flex-shrink-0 mr-4 absolute left-[8%] top-[14%] animate-rocket"
                      src={Book}
                    />
                    <img
                      alt="team"
                      class="w-24 h-24 lg:w-[30%] lg:h-[30%] bg-gray-100 object-cover object-center flex-shrink-0 mr-4"
                      src={About}
                    />
                    <div class="flex-grow">
                      <h2 class="text-gray-900 text-xl lg:text-3xl title-font font-medium">
                        Educational Games
                      </h2>
                    </div>
                  </div>
                  <p class="text-gray-900 lg:text-2xl">
                    Gamification of education is a developing approach for
                    increasing learner’s motivation and engagement by
                    incorporating game design elements in educational
                    environments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default Aboutus
